<template>
  <div class="bg-gray-50" v-if="sectionData">
    <div class="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
      <div class="text-center">
        <h2
          class="text-base font-semibold text-blue-600 tracking-wide uppercase"
        >
          {{ sectionData[0].topText }}
        </h2>
        <p
          class="
            mt-1
            text-4xl
            font-extrabold
            text-gray-900
            sm:text-5xl
            sm:tracking-tight
            lg:text-6xl
          "
        >
          {{ sectionData[0].middleText }}
        </p>
        <p class="mt-3 max-w-4xl mx-auto text-xl sm:mt-5 sm:text-2xl">
          {{ sectionData[0].linkText }}
        </p>
      </div>
    </div>
    <div>
      <SwitchGroup as="div" class="flex items-center mt-10 max-w-4xl mx-auto">
        <div class="inline-block mx-auto">
          <SwitchLabel as="span" class="mr-3">
            <span class="text-sm font-medium text-gray-900"
              >Monthly billing
            </span>
          </SwitchLabel>
          <Switch
            v-on:click="togglePaymentTerm"
            v-model="enabled"
            :class="[
              enabled ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
            ]"
          >
            <span
              aria-hidden="true"
              :class="[
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]"
            />
          </Switch>
          <SwitchLabel as="span" class="ml-3">
            <span class="text-sm font-medium text-gray-900"
              >Annual billing
            </span>
            <span class="text-sm text-gray-500">(Save 10%)</span>
          </SwitchLabel>
        </div>
      </SwitchGroup>
    </div>
    <div class="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
      <div class="relative z-0">
        <div class="absolute inset-0 h-5/6 bg-gray-50 lg:h-2/3" />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="relative lg:grid lg:grid-cols-7" v-if="pricingData">
            <div
              class="
                mx-auto
                max-w-md
                lg:mx-0
                lg:max-w-none
                lg:col-start-1 lg:col-end-3
                lg:row-start-2 lg:row-end-3
              "
            >
              <div
                class="
                  h-full
                  flex flex-col
                  rounded-lg
                  shadow-lg
                  overflow-hidden
                  lg:rounded-none lg:rounded-l-lg
                "
              >
                <div class="flex-1 flex flex-col">
                  <div class="bg-white px-6 py-10">
                    <div>
                      <h3
                        class="text-center text-2xl font-medium text-gray-900"
                        id="tier-hobby"
                      >
                        {{ pricingData[0].planName }}
                      </h3>
                      <div class="mt-4 flex items-center justify-center">
                        <span
                          class="
                            px-3
                            flex
                            items-start
                            text-6xl
                            tracking-tight
                            text-gray-900
                          "
                        >
                          <span class="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span class="font-extrabold">
                            {{
                              isYearly
                                ? pricingData[0].price * 12 -
                                  pricingData[0].price * 12 * 0.1
                                : pricingData[0].price
                            }}
                          </span>
                        </span>
                        <span class="text-xl font-medium text-gray-500">
                          {{ isYearly ? "/year" : "/month" }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      flex-1 flex flex-col
                      justify-between
                      border-t-2 border-gray-100
                      p-6
                      bg-gray-50
                      sm:p-10
                      lg:p-6
                      xl:p-10
                    "
                  >
                    <ul class="space-y-4">
                      <li
                        class="flex items-start"
                        v-for="feat in getFeaturesList(pricingData[0].features)"
                        :key="feat"
                      >
                        <div class="flex-shrink-0">
                          <svg
                            class="flex-shrink-0 h-6 w-6 text-green-500"
                            x-description="Heroicon name: outline/check"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p class="ml-3 text-base font-medium text-gray-500">
                          {{ feat }}
                        </p>
                      </li>
                    </ul>
                    <div class="mt-8">
                      <div class="rounded-lg shadow-md">
                        <router-link
                          to="/Checkout"
                          class="
                            block
                            w-full
                            text-center
                            rounded-lg
                            border border-transparent
                            bg-white
                            px-6
                            py-3
                            text-base
                            font-medium
                            text-blue-600
                            hover:bg-gray-50
                          "
                          aria-describedby="tier-hobby"
                        >
                          Create your profile
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="
                mt-10
                max-w-lg
                mx-auto
                lg:mt-0
                lg:max-w-none
                lg:mx-0
                lg:col-start-3 lg:col-end-6
                lg:row-start-1 lg:row-end-4
              "
            >
              <div class="relative z-10 rounded-lg shadow-xl">
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-0
                    rounded-lg
                    border-2 border-blue-600
                  "
                  aria-hidden="true"
                />
                <div class="absolute inset-x-0 top-0 transform translate-y-px">
                  <div class="flex justify-center transform -translate-y-1/2">
                    <span
                      class="
                        inline-flex
                        rounded-full
                        bg-blue-600
                        px-4
                        py-1
                        text-sm
                        font-semibold
                        tracking-wider
                        uppercase
                        text-white
                      "
                    >
                      Most popular
                    </span>
                  </div>
                </div>
                <div></div>
                <div class="bg-white rounded-t-lg px-6 pt-12 pb-10">
                  <div>
                    <h3
                      class="
                        text-center text-3xl
                        font-semibold
                        text-gray-900
                        sm:-mx-6
                      "
                      id="tier-growth"
                    >
                      {{ pricingData[1].planName }}
                    </h3>
                    <div class="mt-4 flex items-center justify-center">
                      <span
                        class="
                          px-3
                          flex
                          items-start
                          text-6xl
                          tracking-tight
                          text-gray-900
                          sm:text-6xl
                        "
                      >
                        <span class="mt-2 mr-2 text-4xl font-medium"> $ </span>
                        <span class="font-extrabold">
                          {{
                            isYearly
                              ? pricingData[1].price * 12 -
                                pricingData[1].price * 12 * 0.1
                              : pricingData[1].price
                          }}
                        </span>
                      </span>
                      <span class="text-2xl font-medium text-gray-500">
                        {{ isYearly ? "/year" : "/month" }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    border-t-2 border-gray-100
                    rounded-b-lg
                    pt-10
                    pb-8
                    px-6
                    bg-gray-50
                    sm:px-10
                    sm:py-10
                  "
                >
                  <ul class="space-y-4">
                    <li
                      class="flex items-start"
                      v-for="feat in getFeaturesList(pricingData[1].features)"
                      :key="feat"
                    >
                      <div class="flex-shrink-0">
                        <svg
                          class="flex-shrink-0 h-6 w-6 text-green-500"
                          x-description="Heroicon name: outline/check"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 text-base font-medium text-gray-500">
                        {{ feat }}
                      </p>
                    </li>
                  </ul>
                  <div class="mt-10">
                    <div class="rounded-lg shadow-md">
                      <router-link
                        to="/Checkout"
                        class="
                          block
                          w-full
                          text-center
                          rounded-lg
                          border border-transparent
                          bg-blue-500
                          px-6
                          py-4
                          text-xl
                          leading-6
                          font-medium
                          text-white
                          hover:bg-blue-700
                        "
                        aria-describedby="tier-growth"
                      >
                        Create your profile
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                mt-10
                mx-auto
                max-w-md
                lg:m-0
                lg:max-w-none
                lg:col-start-6 lg:col-end-8
                lg:row-start-2 lg:row-end-3
              "
            >
              <div
                class="
                  h-full
                  flex flex-col
                  rounded-lg
                  shadow-lg
                  overflow-hidden
                  lg:rounded-none lg:rounded-r-lg
                "
              >
                <div class="flex-1 flex flex-col">
                  <div class="bg-white px-6 py-10">
                    <div>
                      <h3
                        class="text-center text-2xl font-medium text-gray-900"
                        id="tier-scale"
                      >
                        {{ pricingData[2].planName }}
                      </h3>
                      <div class="mt-4 flex items-center justify-center">
                        <span
                          class="
                            px-3
                            flex
                            items-start
                            text-6xl
                            tracking-tight
                            text-gray-900
                          "
                        >
                          <span class="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span class="font-extrabold">
                            {{
                              isYearly
                                ? pricingData[2].price * 12 -
                                  pricingData[2].price * 12 * 0.1
                                : pricingData[2].price
                            }}
                          </span>
                        </span>
                        <span class="text-xl font-medium text-gray-500">
                          {{ isYearly ? "/year" : "/month" }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      flex-1 flex flex-col
                      justify-between
                      border-t-2 border-gray-100
                      p-6
                      bg-gray-50
                      sm:p-10
                      lg:p-6
                      xl:p-10
                    "
                  >
                    <ul class="space-y-4">
                      <li
                        class="flex items-start"
                        v-for="feat in getFeaturesList(pricingData[0].features)"
                        :key="feat"
                      >
                        <div class="flex-shrink-0">
                          <svg
                            class="flex-shrink-0 h-6 w-6 text-green-500"
                            x-description="Heroicon name: outline/check"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p class="ml-3 text-base font-medium text-gray-500">
                          {{ feat }}
                        </p>
                      </li>
                    </ul>
                    <div class="mt-8">
                      <div class="rounded-lg shadow-md">
                        <router-link
                          to="/Checkout"
                          class="
                            block
                            w-full
                            text-center
                            rounded-lg
                            border border-transparent
                            bg-white
                            px-6
                            py-3
                            text-base
                            font-medium
                            text-blue-500
                            hover:bg-gray-50
                          "
                          aria-describedby="tier-scale"
                        >
                          Create your profile
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Container from "../components/Container.vue";
import Header from "../components/Header.vue";
import { MailIcon, PhoneIcon } from "@heroicons/vue/outline";

import { ref } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { OcContentService } from "../services/ocContentService";
export default {
  components: {
    Header,
    MailIcon,
    PhoneIcon,
    Container,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  setup() {
    const enabled = ref(false);

    return {
      enabled,
    };
  },
  data() {
    return {
      sectionData: null,
      pricingData: null,
      isYearly: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Pricing",
        "KrickersSectionContent"
      );
      this.pricingData = await OcContentService.fetchPageData(
        "Pricing",
        "KrickersPricingPlanItem"
      );
    },
    getFeaturesList(featureString) {
      return featureString.split(", ");
    },
    getStartColumn(ind) {
      if (ind == 0) {
        return 1;
      }
      return ind * 3;
    },
    getEndColumn(ind) {
      return (ind + 1) * 3;
    },
    togglePaymentTerm() {
      this.isYearly = !this.isYearly;
    },
  },
};
</script>
